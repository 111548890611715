<template>
  <quill-editor
    ref="editor"
    :value="value"
    class="plain-editor"
    :class="{
      focus: isFocus && !disabled,
      'is-invalid': state === false,
      'is-valid': state === true,
      disabled: disabled,
    }"
    :options="editorOptions"
    @change="$emit('change', $event)"
    @input="$emit('input', $event)"
    @blur="handleBlur"
    @focus="handleFocus"
  />
</template>

<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { quillEditor as QuillEditor } from 'vue-quill-editor';

export default {
  name: 'PlainEditor',
  components: { QuillEditor },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    state: {
      type: Boolean,
      default: null,
    },
    isChatV2: {
      type: Boolean,
      default: false
    },
    disabled: Boolean,
  },
  data() {
    return {
      isFocus: false,
    };
  },
  computed: {
    editor() {
      return this.$refs.editor.quill;
    },
    editorOptions() {
      return {
        theme: 'bubble',
        placeholder: this.placeholder,
        formats: [],
        modules: {
          syntax: false,
          toolbar: false,
        },
      };
    },
  },
  watch: {
    disabled(disabled) {
      this.editor.enable(!disabled);
    },
    placeholder(placeholder) {
      this.editor.root.dataset.placeholder = placeholder;
    },
  },
  mounted() {
    this.editor.enable(!this.disabled);
    if (this.isChatV2) {
      this.editor.keyboard.bindings[13].unshift({
        key: 13,
        handler: (range, context) => {
          this.$emit('submit', this.value);
        },
      });
    }
  },
  methods: {
    focus() {
      this.editor.focus();
    },
    handleBlur() {
      this.isFocus = false;
      this.$emit('blur');
    },
    handleFocus() {
      this.isFocus = true;
      this.$emit('focus');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes
.plain-editor::v-deep {
  &.disabled {
    color: $custom-select-disabled-color;
    background-color: $custom-select-disabled-bg;
  }
  .ql-editor {
    min-height: 1rem;
    padding: 0;
    &.ql-blank::before {
      font-style: normal;
      left: 1px;
      right: 0;
      color: $text-muted;
    }
  }
  .ql-container {
    font-size: inherit;
    font-family: inherit;
  }
}
</style>
